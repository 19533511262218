<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      Copyright © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://womenontop.gr/"
        target="_blank"
      >Women On Top</b-link>
      
    </span>

    <span class="float-md-right d-md-block">
      <b-link
        href="https://womenontop.gr/changemakers-women-on-top/"
        target="_blank"
      >
        {{ $t('WoT support community') }}
      </b-link>
       -
      <b-link
        :to="{ name: 'terms-and-conditions' }"
        target="_blank"
      >
        {{ $t('Terms and conditions') }}
      </b-link>
       - 
      <b-link
        class="ml-25"
        :to="{ name: 'privacy-policy' }"
        target="_blank"
      >
        {{ $t('Privacy policy') }}
      </b-link>
      - 
      <b-link
        class="ml-25"
        :to="{ name: 'cookies-policy' }"
        target="_blank"
      >
        {{ $t('Cookies policy') }}
      </b-link>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
