<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ fullName }}
        </p>
        <span class="user-status">{{ getUserRole }}</span>
      </div>
      <b-avatar
        size="40"
        :src="getUserAvatar"
        variant="light-primary"
        class="badge-minimal"
      >
        <feather-icon
          v-if="!fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: myAccountPage}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>{{ $t('My account') }}</span>
    </b-dropdown-item>
    <b-dropdown-item v-if="$can('read', 'mentee')"
      :to="{ name: 'purchase-history' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="ShoppingCartIcon"
        class="mr-50"
      />
      <span>{{ $t('Purchase history') }}</span>
    </b-dropdown-item>
    <b-dropdown-item  v-if="canSwitchToAdmin" @click="switchAccount('admin')">
      <div class="text-wrap">
        <feather-icon
          size="16"
          icon="ShuffleIcon"
          class="mr-50"
        />
        <span>{{ $t('Go to admin profile') }}</span>
      </div>
    </b-dropdown-item>
    <b-dropdown-item  v-if="canSwitchToMentor" @click="switchAccount('mentor')">
      <div class="text-wrap">
        <feather-icon
          size="16"
          icon="ShuffleIcon"
          class="mr-50"
        />
        <span>{{ $t('Go to mentor profile') }}</span>
      </div>
    </b-dropdown-item>
    <b-dropdown-item v-if="canSwitchToMentee" @click="switchAccount('mentee')">
      <div class="text-wrap">
        <feather-icon
          size="16"
          icon="ShuffleIcon"
          class="mr-50"
        />
        <span>{{ $t('Go to mentee profile') }}</span>
      </div>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>{{ $t('Logout') }}</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import { mapGetters } from 'vuex'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { useMentorHelpers } from '@mentoring-platform/composables'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      avatarText,
    }
  },
  computed: {
    ...mapGetters('user', [
      'getFullName',
      'getUserRole',
      'getUserAvatar',
      'getUserData',
      'isUserLoggedIn',
      'canSwitchToMentor',
      'canSwitchToMentee',
      'canSwitchToAdmin',
    ]),
    isMentor() {
      return this.$can('read', 'mentor')
    },
    isMentee() {
      return this.$can('read', 'mentee')
    },
    isAdmin() {
      return this.$can('read', 'admin')
    },
    myAccountPage() {
      if (this.isAdmin) {
        return 'admin-my-account'
      }
      else if (this.isMentor) {
        return 'mentor-my-account'
      }
      else if (this.isMentee) {
        return 'mentee-my-account'
      }
    },
    fullName() {
      if (this.isMentor) {
        return this.getMentorVisibleFullName(this.getUserData.mentor)
      }
      return this.getFullName
    }
  },
  methods: {
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push({ name: 'home' })
    },
    async switchAccount(accountType) {
      const accountSwitched = await this.$store.dispatch('user/switchAccount', accountType)
      if(accountSwitched) {
        this.$store.dispatch('mentor/clear')
        this.$store.dispatch('mentee/clear')
        let message = ''
        if(accountType == 'mentor') {
          message = this.$t('Switched to mentor profile')
        }
        else if(accountType == 'mentee') {
          message = this.$t('Switched to mentee profile')
        }
        else if(accountType == 'admin') {
          message = this.$t('Switched to admin profile')
        }
        this.$root.$bvToast.toast(message, {
          title: this.$t('Confirmation'),
          variant: 'success',
          solid: true,
        })
        const route = getHomeRouteForLoggedInUser(this.$store.getters['user/getUserRole'])
        if (this.$route.name !== route.name) {
          this.$router.push(route)
        }
      }
      else {
        this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
          title: this.$t('Caution'),
          variant: 'danger',
          solid: true,
        })
        await this.$store.dispatch('user/logout')
        if (this.$route.name !== 'home') {
          this.$router.push({ name: 'home' })
        }
        return
      }
    }
  },
  setup() {
    const { getMentorVisibleFullName } = useMentorHelpers()

    return {
      getMentorVisibleFullName
    }
  }
}
</script>
