export const adminNavItems = [
  /*{
    title: 'Dashboard - admin menu header',
    route: 'admin-dashboard',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'admin'
  },*/
  {
    header: 'Mentors - admin menu header',
    action: 'read',
    resource: 'admin'
  },
  {
    title: 'Mentors',
    route: 'admin-mentors',
    icon: 'UserIcon',
    action: 'read',
    resource: 'admin'
  },
  {
    title: 'Pending applications',
    route: 'admin-pending-applications',
    icon: 'ClipboardIcon',
    action: 'read',
    resource: 'admin'
  },
  {
    title: 'Pending edit requests',
    route: 'admin-pending-edit-requests',
    icon: 'RepeatIcon',
    action: 'read',
    resource: 'admin'
  },
  /*{
    title: 'Create new mentor',
    route: 'admin-create-new-mentor',
    icon: 'UserPlusIcon',
    action: 'read',
    resource: 'admin'
  },*/
  {
    header: 'Mentees - admin menu header',
    action: 'read',
    resource: 'admin'
  },
  {
    title: 'Mentees',
    route: 'admin-mentees',
    icon: 'UserIcon',
    action: 'read',
    resource: 'admin'
  },
  {
    title: 'Purchased subscriptions',
    route: 'admin-purchased-subscriptions',
    icon: 'ShoppingBagIcon',
    action: 'read',
    resource: 'admin'
  },
  /*{
    title: 'Mentor suggestion requests',
    route: 'admin-mentor-suggestion-requests',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'admin'
  },
  {
    title: 'Create new mentee',
    route: 'admin-create-new-mentee',
    icon: 'UserPlusIcon',
    action: 'read',
    resource: 'admin'
  },*/
  {
    title: 'Scholarships management',
    route: 'admin-scholarships-management',
    icon: 'AwardIcon',
    action: 'read',
    resource: 'admin'
  },
  {
    header: 'Mentorships - admin menu header',
    action: 'read',
    resource: 'admin'
  },
  {
    title: 'Mentorships',
    route: 'admin-mentorships',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'admin'
  },
  {
    title: 'Pending mentorship requests',
    route: 'admin-pending-mentorship-requests',
    icon: 'UserPlusIcon',
    action: 'read',
    resource: 'admin'
  },
  {
    title: 'Rejected mentorship requests',
    route: 'admin-rejected-mentorship-requests',
    icon: 'UserXIcon',
    action: 'read',
    resource: 'admin'
  }
  /*{
    title: 'Create new mentorship',
    route: 'admin-create-new-mentorship',
    icon: 'PlusIcon',
    action: 'read',
    resource: 'admin'
  },
  {
    header: 'Reports - admin menu header',
    action: 'read',
    resource: 'admin'
  }*/
]
