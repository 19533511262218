<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <feather-icon
        icon="GlobeIcon"
        size="20"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="changeLocale(localeObj.locale)"
    >
      <feather-icon
        icon="GlobeIcon"
        size="20"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import { useLocales } from '@mentoring-platform/composables'
import { localize } from 'vee-validate'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
    isUserLoggedIn() {
      return this.$store.getters['user/isUserLoggedIn']
    },
  },
  created() {
    if (!this.isUserLoggedIn) {
      const storedLocale = localStorage.getItem('locale')
      if (storedLocale) {
        this.$i18n.locale = storedLocale
        localize(storedLocale)
      } else {
        localStorage.setItem('locale', this.$i18n.locale)
      }
    } else {
      localStorage.removeItem('locale')
      localize(this.$i18n.locale)
    }
  },
  methods: {
    changeLocale(newLocale) {
      if (this.isUserLoggedIn) {
        this.$store.dispatch('user/changeLocale', { locale: newLocale })
      } else {
        localStorage.setItem('locale', newLocale)
      }
      this.$i18n.locale = newLocale
      localize(newLocale)
    },
  },
  setup() {
    const { locales } = useLocales()

    return {
      locales,
    }
  },
}
</script>

<style>

</style>
